/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';

import { OffLineComponent } from './OffLineComponent';
import { OnLineComponent } from './OnLineComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: 500,
  },
  exampleModal: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
  exampleModalPopup: { maxWidth: '700px', margin: '0px auto', overflow: 'hidden', padding: '20px' },
  exampleAlert: { filter: 'none', marginBottom: '20px' },
  blInput: { borderBottom: '1px solid #999' },
}));

const LoginForm = ({ loading, getViolation, isUnderMaintenance }) => {
  const classes = useStyles();

  return isUnderMaintenance ? (
    <OffLineComponent classes={classes} />
  ) : (
    <OnLineComponent classes={classes} loading={loading} getViolation={getViolation} />
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  getViolation: PropTypes.func,
};

LoginForm.defaultProps = {
  loading: false,
  getViolation: () => {},
};

export default LoginForm;
