/** @format */

import React from 'react';
import { Paper, Typography } from '@mui/material';

export const OffLineComponent = ({ classes }) => (
  <Paper className={classes.root}>
    <Typography
      sx={{
        variant: 'h1',
        textAlign: 'center',
        color: '#7c7b7b',
        fontFamily: '300 14px "Open Sans"',
        fontSize: '40px',
        pb: 3,
        fontWeight: 'normal',
      }}>
      Violation Payment
    </Typography>

    <Typography variant='h4' mb={1} textAlign='center'>
      Hello, we're currently under maintenance
    </Typography>

    <Typography variant='body1'>
      Please try again later. If the problem persists, please contact{' '}
      <strong>
        <a href='tel:+018552520086'>(855) 252-0086</a>
      </strong>
      , Monday through Friday from 9am to 5pm Eastern. Or{' '}
      <strong>
        <a href='https://violationpayment.net' target='_blank' rel='noreferrer'>
          click here
        </a>
      </strong>{' '}
      to return to the violationpayment.net website.
    </Typography>
  </Paper>
);
