/** @format */
import React, { useState } from 'react';

import { Alert, Button, Grid, Link, Modal, Paper, Typography } from '@mui/material';

import { AsyncButton, ControlledTextField } from 'components';
import { setPublicCredentials } from 'utils/localStorage';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const validationSchema = yup.object().shape({
  violationNumber: yup.string().required('Citation number is required'),
  accessKey: yup.string().required('PIN is required'),
});

export const OnLineComponent = ({ classes, loading, getViolation }) => {
  const [errorMsg, setErrorMsg] = useState('');

  const { control, errors, getValues, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = async () => {
    setErrorMsg('');
    const formDataIsValid = await trigger();

    if (formDataIsValid) {
      const formData = getValues();

      const alphanumericVal = Number.isNaN(Number(formData.violationNumber));
      if (!alphanumericVal) {
        setErrorMsg('The citation number entered does not match the correct format. Does it start wtih BL-?');
        return;
      }

      formData.violationNumber = formData.violationNumber.toUpperCase();
      formData.accessKey = formData.accessKey.toUpperCase();

      setPublicCredentials(formData);

      const result = await getViolation(formData);
      if (result.type === 'error') setErrorMsg(result.msg);
    }
  };

  const [showCitationExampleModal, setShowCitationExampleModal] = useState(false);

  return (
    <>
      <Modal
        open={showCitationExampleModal}
        onClose={() => setShowCitationExampleModal(!showCitationExampleModal)}
        className={classes.exampleModal}>
        <Grid>
          <Paper className={classes.exampleModalPopup}>
            <Button onClick={() => setShowCitationExampleModal(!showCitationExampleModal)}>Close</Button>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <img src='/citation_example.jpg' style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={12} sm={6} p={3}>
                <Typography variant='h2' mb={2}>
                  Citation Number
                </Typography>
                <Typography>
                  If the <strong>Citation Number</strong> on the top right of your citation does not start with{' '}
                  <strong>BL-</strong>, similar to the example,{' '}
                  <Link href='https://www.violationpayment.net/pay-view-citation.html' target='_blank' rel='noopener'>
                    click here
                  </Link>{' '}
                  to be redirected to the correct page.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Modal>
      <Paper className={classes.root}>
        <Grid container direction='column' spacing={4}>
          <Grid item>
            <Typography
              sx={{
                variant: 'h1',
                textAlign: 'center',
                pb: 3,
                color: '#7c7b7b',
                fontFamily: '300 14px "Open Sans"',
                fontSize: '40px',
                fontWeight: 'normal',
              }}>
              Violation Payment
            </Typography>

            <Alert severity='info' color='error' className={classes.exampleAlert}>
              <Button
                style={{ padding: 0, marginBottom: 2 }}
                component={Link}
                onClick={() => setShowCitationExampleModal(!showCitationExampleModal)}>
                Click here
              </Button>{' '}
              if your violation does not begin with <strong>BL-</strong>
            </Alert>

            <Typography variant='body1' component='h6'>
              Please enter the Citation Number and PIN found on your citation document to retrieve your violation
              details and make payment.
            </Typography>
          </Grid>

          <>
            {errorMsg && (
              <Grid item>
                <Typography color='error'>{errorMsg}</Typography>
              </Grid>
            )}

            <Grid container item className={classes.inputField} spacing={1}>
              <Grid container item flexDirection='column' justifyContent='flex-end' xs={2}>
                <Grid item className={classes.blInput}>
                  <Typography variant='h3' textAlign='right'>
                    BL-
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <ControlledTextField
                  control={control}
                  inputProps={{ style: { textTransform: 'uppercase', fontSize: '16px' } }}
                  name='violationNumber'
                  label='Citation Number'
                  error={!!errors.violationNumber}
                  errorMessage={errors.violationNumber?.message}
                  onEnterPress={handleSubmit}
                />
              </Grid>
            </Grid>

            <Grid item className={classes.inputField}>
              <ControlledTextField
                className={classes.inputField}
                control={control}
                inputProps={{ style: { textTransform: 'uppercase', fontSize: '16px' } }}
                name='accessKey'
                label='PIN'
                error={!!errors.accessKey}
                errorMessage={errors.accessKey?.message}
                onEnterPress={handleSubmit}
              />
            </Grid>

            <Grid item>
              <AsyncButton color='primary' loading={loading} onClick={handleSubmit} variant='contained' fullWidth>
                Lookup Violation
              </AsyncButton>
            </Grid>
          </>
        </Grid>
      </Paper>
    </>
  );
};
